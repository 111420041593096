<template>
  <window-content>
    <user-form @updateUser="updateUser" :user-id="id" edit-username :role="role" :full="tiposForFull" :personId="personId" :relational-user="relationalUser" />
  </window-content>
</template>

<script>
import {
  WindowContent,
} from 'uloc-vue-plugin-erp'
import UserForm from "components/usuario/v2/components/UserForm.vue"

export default {
  name: 'UsuarioWindow',
  provide: function () {
    return {
      userPage: this
    }
  },
  props: {
    id: null,
    router: null,
    role: null,
    personId: null,
    relationalUser: {required: false, default: false},
  },
  components: {
    UserForm,
    // WindowHeader,
    WindowContent,
  },
  mounted() {
    this.$router = this.router
  },
  computed: {
    tiposForFull () {
      if (!this.role) {
        return false
      }
      return ['ROLE_COMITENTE'].includes(this.role) // Adaptar com demais ROLEs necessários
    }
  },
  methods: {
    updateUser () {
      console.log('User updated')
    }
  }
}
</script>
